var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "absolute z-50 top-0 w-full my-6 notification" },
    _vm._l(_vm.notifications, function(notification) {
      return _c(
        "div",
        {
          key: notification.id,
          staticClass:
            "animate-right flex max-w-lg w-full mx-auto bgcolor shadow-md rounded-lg overflow-hidden mt-8"
        },
        [
          notification.type === "success"
            ? _c(
                "div",
                { staticClass: "flex justify-center items-center green" },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "h-6 w-6",
                      attrs: {
                        viewBox: "0 0 40 40",
                        xmlns: "http://www.w3.org/2000/svg"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM16.6667 28.3333L8.33337 20L10.6834 17.65L16.6667 23.6166L29.3167 10.9666L31.6667 13.3333L16.6667 28.3333Z"
                        }
                      })
                    ]
                  )
                ]
              )
            : _vm._e(),
          notification.type === "error"
            ? _c(
                "div",
                { staticClass: "flex justify-center items-center red" },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "h-6 w-6",
                      attrs: {
                        viewBox: "0 0 40 40",
                        xmlns: "http://www.w3.org/2000/svg"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z"
                        }
                      })
                    ]
                  )
                ]
              )
            : _vm._e(),
          notification.type === "info"
            ? _c(
                "div",
                { staticClass: "flex justify-center items-center blue" },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "h-6 w-6",
                      attrs: {
                        viewBox: "0 0 40 40",
                        xmlns: "http://www.w3.org/2000/svg"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM21.6667 28.3333H18.3334V25H21.6667V28.3333ZM21.6667 21.6666H18.3334V11.6666H21.6667V21.6666Z"
                        }
                      })
                    ]
                  )
                ]
              )
            : _vm._e(),
          _c("div", { staticClass: "py-4" }, [
            _c("div", { staticClass: "mx-3" }, [
              _c("p", { staticClass: "font-semibold" }, [
                _vm._v(_vm._s(notification.text))
              ])
            ])
          ]),
          _c("div", { staticClass: "px-4" }, [
            _c(
              "span",
              {
                staticClass:
                  "close-icon opacity-75 cursor-pointer absolute right-0 py-2 px-3 hover:opacity-100",
                on: {
                  click: function($event) {
                    return _vm.removeNotification(notification.id)
                  }
                }
              },
              [_vm._v(" × ")]
            )
          ])
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }