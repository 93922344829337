<template>
  <div class="absolute z-50 top-0 w-full my-6 notification">
    <div
      class="animate-right flex max-w-lg w-full mx-auto bgcolor shadow-md rounded-lg overflow-hidden mt-8"
      v-for="notification in notifications"
      :key="notification.id"
    >
      <!-- success -->
      <div
        class="flex justify-center items-center green"
        v-if="notification.type === 'success'"
      >
        <svg
          class="h-6 w-6"
          viewBox="0 0 40 40"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM16.6667 28.3333L8.33337 20L10.6834 17.65L16.6667 23.6166L29.3167 10.9666L31.6667 13.3333L16.6667 28.3333Z"
          ></path>
        </svg>
      </div>

      <!-- error -->
      <div
        class="flex justify-center items-center red"
        v-if="notification.type === 'error'"
      >
        <svg
          class="h-6 w-6"
          viewBox="0 0 40 40"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z"
          ></path>
        </svg>
      </div>

      <!-- info -->
      <div
        class="flex justify-center items-center blue"
        v-if="notification.type === 'info'"
      >
        <svg
          class="h-6 w-6"
          viewBox="0 0 40 40"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM21.6667 28.3333H18.3334V25H21.6667V28.3333ZM21.6667 21.6666H18.3334V11.6666H21.6667V21.6666Z"
          />
        </svg>
      </div>

      <div class="py-4">
        <div class="mx-3">
          <p class="font-semibold">{{ notification.text }}</p>
        </div>
      </div>
      <div class="px-4">
        <span
          class="close-icon opacity-75 cursor-pointer absolute right-0 py-2 px-3 hover:opacity-100"
          @click="removeNotification(notification.id)"
        >
          &times;
        </span>
      </div>
    </div>
  </div>
</template>

<script>
let count = 0;
const generateId = () => {
  count = count + 1;
  return count;
};
import { Bus } from "../../eventBus";
export default {
  data: () => ({
    notifications: [],
  }),
  created() {
    Bus.$on("notification", (notification, timeout) => {
      notification.id = generateId();
      this.notifications.push(notification);
      setTimeout(() => {
        this.removeNotification(notification.id);
      }, timeout || 3000);
    });
  },
  methods: {
    removeNotification(id) {
      this.notifications.splice(
        this.notifications.findIndex((n) => n.id === id),
        1
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.bgcolor {
  background-color: #005eef;
  color: #fff;
  .red {
    background-color: transparent;
    svg {
      fill: #f05959;
      margin-left: 1rem;
    }
  }
  .green {
    background-color: transparent;
    svg {
      fill: #06ff9d;
      margin-left: 1rem;
    }
  }
  .blue {
    background-color: transparent;
    svg {
      fill: #9bc0f8;
      margin-left: 1rem;
    }
  }
}
.close-icon {
  cursor: pointer;
  font-size: 20px;
  margin-top: -3px;
}
.animate-right {
  position: relative;
  animation: animateright 0.4s;
}
@keyframes animateright {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
</style>
